<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Уважаемые коллеги!</h1>
          <!-- <p class="lead colored">Сообщаем, что 18&nbsp;декабря витрина будет закрыта для&nbsp;проведения налогового агентирования. Просьба обменять баллы на&nbsp;сертификаты до&nbsp;18&nbsp;числа. Витрина снова станет доступна 9&nbsp;января.</p> -->
          <p class="lead"><span class="colored">EXEED</span> благодарит вас за плодотворное сотрудничество!</p>
          <hr>
          <h4>Каталог вознаграждений</h4>
          <p>В&nbsp;каталоге вознаграждений Вы&nbsp;найдете электронные подарочные сертификаты известных брендов. Вы&nbsp;можете выбрать любой сертификат.</p>
          <p>Вы&nbsp;можете выбирать любое количество сертификатов в&nbsp;день без ограничений.</p>
          <p>При достижении суммы приза более 4000&nbsp;рублей Вам необходимо будет заполнить Анкету и&nbsp;приложить документы для декларации дохода и&nbsp;уплате подоходного налога (НДФЛ будет уплачен за&nbsp;Вас). После проверки документов, необходимых для уплаты подоходного налога, и&nbsp;<b>подписания Акт <nobr>приема-передачи</nobr> подарка с&nbsp;помощью нового сервиса <nobr>смс-подписания</nobr></b>, Подарочные Сертификаты будут направлены Вам на&nbsp;указанный <nobr>e-mail</nobr> в&nbsp;течение 1&ndash;7 рабочих дней.</p>
          <p class="colored">Срок действия активации сертификата не&nbsp;более 1 (одного) календарного месяца с&nbsp;момента информирования о&nbsp;начислении баллов в&nbsp;личном кабинете.</p>
          <p class="colored">В&nbsp;случае не&nbsp;активации сертификата в&nbsp;указанный период&nbsp;&mdash; начисленные в&nbsp;личном кабинете баллы аннулируются.</p>
          <router-link class="button button--rounded button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          <br>
          <br>
          <hr>
          <p><b>Мы&nbsp;всегда рады ответить на&nbsp;Ваши вопросы:</b></p>
          <p><b>Телефон службы поддержки:</b><br>
          <a href="tel:88007009090">8&nbsp;(800)&nbsp;700-90-90</a></p>
          <p><b>Электронная почта:</b><br>
          <a href="mailto:support@giftery.ru">support@giftery.ru</a></p>
          <br>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
